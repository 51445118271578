import * as yup from 'yup'
import customFieldTypes from '@/constants/customFieldTypes'

const keyPattern = /^[a-z_0-9\\]*$/
export const advancedFieldMgmtSchema = ({ $t }) => yup
  .object()
  .shape({
    name: yup.string()
      .nullable()
      .required()
      .max(50, `${$t('Max')} 50`),

    label: yup.string()
      .nullable()
      .required()
      .max(50, `${$t('Max')} 50`),

    key: yup.string()
      .nullable()
      .required()
      .min(2, `${$t('Min')} 2`)
      .max(30, `${$t('Max')} 30`),

    defaultValue: yup.string()
      .nullable()
      .max(200, `${$t('Max')} 200`),

    type: yup.string()
      .required()
      .transform(v => (v === null ? '' : v)),

    query: yup.string()
      .matches(/{{this\.id}}/, 'Missing {{this.id}}')
      // .matches(/^SELECT.*/i, 'SELECT query only')
      .when('type', {
        is: type => type === customFieldTypes.Query,
        then: yup
          .string()
          .nullable()
          .required(),
        otherwise: yup
          .string()
          .nullable(),
      }),

    minCharacterCount: yup.number()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Min limit 0')
      .max(2147483647, 'Max limit 2,147,483,647'),

    maxCharacterCount: yup.number()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .min(1, 'Min limit 1')
      .max(2147483647, 'Max limit 2,147,483,647')
      .min(yup.ref('minCharacterCount'), 'Must be greater than Min char count'),

    minValue: yup.number()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Min limit 0')
      .max(2147483647, 'Max limit 2,147,483,647'),

    maxValue: yup.number()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .min(1, 'Min limit 1')
      .max(2147483647, 'Max limit 2,147,483,647')
      .min(yup.ref('minValue'), 'Must be greater than Min value'),
  })

export default {
  advancedFieldMgmtSchema,
}

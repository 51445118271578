<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :loading="loading"
    :form-title="formTitle"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="advancedFieldMgmtForm"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
    >
      <template v-if="resourceId">
        <prozess-input
          id="field_id"
          v-model="resource.id"
          :placeholder="$t('Field ID')"
          field="field_id"
          name="field_id"
          disabled
        />
        <prozess-input
          id="createdDate"
          v-model="resource.createdDate"
          :placeholder="$t('Date Time')"
          field="createdDate"
          name="createdDate"
          disabled
        />
        <prozess-input
          id="createdByUsername"
          v-model="resource.createdByUsername"
          :placeholder="$t('Created By')"
          field="createdByUsername"
          name="createdByUsername"
          disabled
        />
      </template>
      <prozess-input
        v-model="resource.name"
        :placeholder="$t('Title')"
        icon="TypeIcon"
        field="name"
        name="name"
        :error="$hasError('name')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.label"
        :placeholder="$t('Label')"
        icon="TagIcon"
        field="label"
        :error="$hasError('label')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.key"
        :disabled="!!resourceId"
        :placeholder="$t('Key')"
        icon="KeyIcon"
        field="key"
        :hint="$t('The field key must be unique')"
        :error="$hasError('key')"
        @input="handleKeyInput"
        @enter="save"
      />
      <prozess-input
        v-if="showDefaultValueField && !isTypeDirectMap"
        v-model="resource.defaultValue"
        :disabled="isTypeDirectMap"
        :placeholder="$t('Default Value')"
        icon="TypeIcon"
        field="defaultValue"
        :error="$hasError('defaultValue')"
        @enter="save"
      />
      <b-input-group class="input-group-merge mb-2 form-sidebar__add-option">
        <b-input-group-prepend is-text>
          <feather-icon icon="GridIcon" />
        </b-input-group-prepend>
        <prozess-select
          ref="roleId"
          v-model="resource.type"
          style="flex: 1"
          :placeholder="$t('Type')"
          :options="types"
          :reduce="(type) => type.value"
          :disabled="!!resourceId"
        />
        <span v-if="$hasError('type')" class="invalid-feedback">
          {{ $t($hasError('type')) }}
        </span>
      </b-input-group>
      <b-input-group v-if="isTypeDirectMap" class="input-group-merge mb-2 form-sidebar__add-option">
        <b-input-group-prepend is-text>
          <feather-icon icon="ArrowRightIcon" />
        </b-input-group-prepend>
        <prozess-select
          v-model="resource.defaultValue"
          style="flex: 1"
          :placeholder="$t('Field')"
          :options="companyContactFields"
          :get-option-label="option => option.label"
          :reduce="(option) => option.value"
          :disabled="!!resourceId"
        />
        <span v-if="$hasError('field')" class="invalid-feedback">
          {{ $t($hasError('field')) }}
        </span>
      </b-input-group>

      <template v-if="isTypeListLike">
        <div class="form-sidebar__options">
          <div class="p-1 form-sidebar__options-header">
            <h5>{{ $t('Options') }}</h5>
          </div>
          <div class="p-1">
            <b-form-tags v-model="resource.values" input-id="tags-basic" />
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <span
            v-show="$hasError('values')"
            class="invalid-feedback"
            style="flex: 1"
          >
            {{ $t($hasError('values')) }}
          </span>
          <span style="flex: 2;" class="field-hint">
            {{ $t('Hit enter after typing to add a value.') }}
          </span>
        </div>
      </template>

      <template v-if="isTypeDate">
          <b-form-checkbox
            v-model="resource.dateAutoset"
            class="tw-m-0 tw-mb-6"
            switch
            inline
            @keyup.enter.native="save"
          >
            <span style="font-size: 1rem">{{ $t('Auto-set Current Date') }}</span>
        </b-form-checkbox>
          <b-form-checkbox
            v-model="resource.dateEditable"
            class="tw-m-0 tw-mb-6"
            switch
            inline
            @keyup.enter.native="save"
          >
            <span style="font-size: 1rem">{{ $t('Is Date Editable') }}</span>
        </b-form-checkbox>
      </template>

      <template v-if="isTypeDateTime">
          <b-form-checkbox
            v-model="resource.dateAutoset"
            class="tw-m-0 tw-mb-6"
            switch
            inline
            @keyup.enter.native="save"
          >
            <span style="font-size: 1rem">{{ $t('Auto-set Current Date/Time') }}</span>
        </b-form-checkbox>
          <b-form-checkbox
            v-model="resource.dateEditable"
            class="tw-m-0 tw-mb-6"
            switch
            inline
            @keyup.enter.native="save"
          >
            <span style="font-size: 1rem">{{ $t('Is Date/Time Editable') }}</span>
        </b-form-checkbox>
      </template>
      <prozess-field-wrapper
        v-if="resource.type === CustomFieldTypes.Query"
        :error="$hasError('query')"
        :hint="`Must contain {{this.id}} placeholder`"
      >
        <b-form-textarea
          v-model="resource.query"
          :placeholder="$t('query-field-tooltip')"
          rows="3"
        />
      </prozess-field-wrapper>
      <div class="mt-1 pt-1 tw-flex tw-flex-wrap tw-mb-6">
        <b-form-checkbox
          v-model="resource.favorite"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Favorite') }}</span>
        </b-form-checkbox>
        <b-form-checkbox
          v-if="resource.type !== 'MAPPING'"
          v-model="resource.required"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Required') }}</span>
        </b-form-checkbox>
        <b-form-checkbox
          v-model="resource.searchable"
          :disabled="isSearchable"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Searchable') }}</span>
        </b-form-checkbox>
        <b-form-checkbox
          v-model="resource.unique"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Unique') }}</span>
        </b-form-checkbox>
        <b-form-checkbox
          v-model="resource.partOfName"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          :disabled="this.titleSetDisabled"
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Title') }}</span>
        </b-form-checkbox>
        <b-form-checkbox
          v-model="resource.hidden"
          :disabled="disableHiddenToggle"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Hidden') }}</span>
        </b-form-checkbox>

        <b-form-checkbox
          v-if="resource.type !== 'QUERY'"
          v-model="resource.filterable"
          class="tw-w-6/12 tw-m-0 tw-mb-6"
          switch
          inline
          @keyup.enter.native="save"
        >
          <span style="font-size: 1rem">{{ $t('Filterable') }}</span>
        </b-form-checkbox>
      </div>
      <div v-if="isTypeQuery" class="my-1">
        <feather-icon icon="InfoIcon" class="text-secondary tw-mr-1" />
        {{ $t('afm-optional-query-fields') }}
      </div>
      <div v-if="resource.required" class="my-1">
        <feather-icon icon="InfoIcon" class="text-secondary tw-mr-1" />
        {{ $t('afm-no-hidden-required-field') }}
      </div>
      <div
        v-if="isFieldTypeText(resource.type)"
        class="form__validation--character-count tw-flex tw-gap-2"
      >
        <div class="tw-flex-1">
          <prozess-input
            v-model="resource.minCharacterCount"
            type="number"
            :placeholder="$t('Min char count')"
            field="minCharacterCount"
            name="minCharacterCount"
            :error="$hasError('minCharacterCount')"
            @enter="save"
          />
        </div>
        <div class="tw-flex-1">
          <prozess-input
            v-model="resource.maxCharacterCount"
            type="number"
            :placeholder="$t('Max char count')"
            field="maxCharacterCount"
            name="maxCharacterCount"
            :error="$hasError('maxCharacterCount')"
            @enter="save"
          />
        </div>
      </div>
      <div
        v-if="isFieldTypeNumeric(resource.type)"
        class="form__validation--digit-value tw-flex tw-gap-2"
      >
        <div class="tw-flex-1">
          <prozess-input
            v-model="resource.minValue"
            type="number"
            :placeholder="$t('Min value')"
            field="minValue"
            name="minValue"
            :error="$hasError('minValue')"
            @enter="save"
          />
        </div>
        <div class="tw-flex-1">
          <prozess-input
            v-model="resource.maxValue"
            type="number"
            :placeholder="$t('Max value')"
            field="maxValue"
            name="maxValue"
            :error="$hasError('maxValue')"
            @enter="save"
          />
        </div>
      </div>

      <div v-if="(resource.defaultMaxCharCount || resource.defaultMinCharCount)" class="my-1">
        <feather-icon icon="InfoIcon" class="text-secondary tw-mr-1" />
        {{ $t('Min value') }}: {{ resource.defaultMinCharCount }} {{ $t('Max value') }}: {{ resource.defaultMaxCharCount }}
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { Entity, Schema } from '@/constants/app'
import { advancedFieldMgmtSchema } from '@/schema/advancedFieldMgmt'
import { preventSpecialChars } from '@/helpers/app'
import { isFieldTypeNumeric, isFieldTypeText } from '@/helpers/field'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import ProzessSelect from '@/@core/components/ProzessSelect.vue'
import ProzessInput from '@/@core/components/ProzessInput.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'
import CustomFieldEntities from '@/constants/customFieldEntities'
import advanceSearchService from '@/services/advancedSearch'
import dayjs from 'dayjs'

const defaultResource = {
  favorite: false,
  searchable: false,
  partOfName: false,
  entity: null,
  type: null,
  key: null,
  label: null,
  name: null,
  required: false,
  unique: false,
  minCharacterCount: null,
  maxCharacterCount: null,
  minValue: null,
  maxValue: null,
  hidden: false,
  filterable: false,
  defaultValue: null,
  query: null,
  values: [],
  removeValues: [],
  newValues: [],
  companyFields: [],
  dateEditable: true,
  dateAutoset: false,
}

export default {
  components: {
    ProzessSelect,
    ProzessInput,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    customFields: {
      type: Array,
      default: () => [],
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    schemaKey: {
      type: String,
      default: null,
    },

    tableKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      CustomFieldTypes,
      CustomFieldEntities,
      resource: this.$lodash.cloneDeep(defaultResource),
      options: [],
      saving: false,
      loading: true,
      errors: [],
      keyFieldTouched: false,
      isSearchable: true,
      oldValues: [],
      companyContactFields: [],
      titleSetDisabled: false,
    }
  },
  computed: {
    disableHiddenToggle() {
      return this.resource.required
    },

    disableRequiredToggle() {
      return this.isCompanyContactRole || this.isTypeQuery
    },

    editingDisabled() {
      return !!this.resourceId && !this.resource.customField
    },

    formTitle() {
      return this.resourceId ? 'Edit Field' : 'Add New Field'
    },

    isCompanyContactRole() {
      return (
        this.schemaKey === Schema.CRM
        && this.tableKey === Entity.COMPANY_HAS_CONTACT
        && this.resource?.key === 'role'
      )
    },
    isTypeListLike() {
      return [CustomFieldTypes.List, CustomFieldTypes.MultiSelect].includes(
        this.resource.type,
      )
    },
    isTypeDate() {
      return this.resource.type === CustomFieldTypes.Date
    },
    isTypeDateTime() {
      return this.resource.type === CustomFieldTypes.DateTime
    },
    isTypeQuery() {
      return this.resource.type === CustomFieldTypes.Query
    },
    isTypeDirectMap() {
      return this.resource.type === 'MAPPING'
    },

    resourceKey() {
      return this.resourceId ? this.resourceId.split('.')[2] : null
    },

    showDefaultValueField() {
      return this.resource.type !== CustomFieldTypes.Query
    },

    types() {
      const ttypes = Object.keys(CustomFieldTypes).map(key => ({
        label: this.$t(key),
        value: CustomFieldTypes[key],
      }))
      if (this.isCompanyContactForm()) {
        ttypes.push({ label: this.$t('Direct Mappping'), value: 'MAPPING' })
      }
      return ttypes
    },
  },

  watch: {
    'resource.name': function (value) {
      if (this.resourceId) {
        return
      }

      if (this.keyFieldTouched === false) {
        const replaceSpecialChar = value?.replace(/[^a-z_\s\u00c0-\u017e]/gi, '') ?? ''
        this.resource.key = this.$case.snake(replaceSpecialChar)
      }
    },

    'resource.type': {
      handler(value) {
        if (!this.resourceId) {
          this.resource = {
            ...this.resource,
            minCharacterCount: null,
            maxCharacterCount: null,
            minValue: null,
            maxValue: null,
          }
        }

        this.isSearchable = (
          this.resource.type === CustomFieldTypes.Date
          || this.resource.type === CustomFieldTypes.Bool
          || this.resource.type === CustomFieldTypes.DateTime
        )

        if (this.isTypeQuery) {
          this.resource.required = false
        }
      },

      immediate: true,
    },

    'resource.required': {
      handler(required) {
        if (required) {
          this.resource.hidden = false
        }
      },

      immediate: true,
    },

    'resource.values': {
      handler() {
        if (this.resourceId) this.resource.newValues = this.resource?.values?.filter(item => !this.oldValues.includes(item))
      },
    },

    visible: {
      async handler(visible) {
        if (visible) {
          this.reset()
          if (this.isCompanyContactForm()) {
            await this.getCompanyAndContactFieldOptions()
          }
          if (this.resourceId) {
            await this.getData()
          }

          this.setForcedValues()
          await this.checkEntityHasTitle()
        }
      },

      immediate: true,
    },
  },
  mounted() {
    this.getRelationships()
    if (this.schemaKey === Schema.CRM && this.tableKey === Entity.COMPANY_HAS_CONTACT) {
      this.getCompanyAndContactFieldOptions()
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },

    async checkEntityHasTitle() {
      this.loading = true
      this.titleSetDisabled = false
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: this.schemaKey,
          table: this.tableKey,
        }),
      )
      const fieldTitle = response.data.filter(i => i.partOfName)
      const titleKeys = fieldTitle.map(i => i.key)
      if (titleKeys.length > 0 && !titleKeys.includes(this.resource.key)) {
         this.titleSetDisabled = true
      }
      this.loading = false
    },
    async getData() {
      this.loading = true
      const [schema, table, key] = this.resourceId.split('.')

      const { response } = await this.$async(
        advancedFieldMgmtService.getOne({ schema, table, key }),
      )
      this.resource = response.data
      this.resource.dateEditable = !this.resource.readOnly
      this.oldValues = this.resource.values
      this.resource.createdDate = response.data.createdDate ? dayjs(response.data.createdDate).format('MMMM DD, YYYY HH:mm') : ''
      this.loading = false
    },

    async getCompanyAndContactFieldOptions() {
      const { response } = await this.$async(advanceSearchService.getEntities())
      const allEntity = response.data
      const companyColumns = allEntity.filter(e => e.key === 'company')[0].columns.map(item => ({
            value: 'company.'.concat(item.key),
            label: item.label.concat(' (Company)'),
          }))
      const contactColumns = allEntity.filter(e => e.key === 'contact')[0].columns.map(item => ({
            value: 'contact.'.concat(item.key),
            label: item.label.concat(' (Contact)'),
          }))
      this.companyContactFields = companyColumns.concat(contactColumns)
    },

    handleKeyInput() {
      this.keyFieldTouched = true
    },
    isFieldTypeNumeric,
    isFieldTypeText,

    preventSpecialChars(e) {
      return preventSpecialChars(e, {
        callback: data => {
          this.resource.key = data
          this.$forceUpdate()
        },
        regExp: '^[a-z_]+$',
      })
    },
    isCompanyContactForm() {
      return (
        this.schemaKey === Schema.CRM
        && this.tableKey === Entity.COMPANY_HAS_CONTACT
      )
    },
    reset() {
      this.errors = []
      this.resource = this.$lodash.cloneDeep(defaultResource)
      this.loading = false
      this.saving = false
      this.keyFieldTouched = false
    },

    async save() {
      await this.validate()

      if (this.errors.length) {
        return
      }

      this.saving = true

      const params = {
        schema: this.schemaKey,
        table: this.tableKey,
        key: this.resourceKey,
      }

      const {
        minCharacterCount,
        maxCharacterCount,
        minValue,
        maxValue,
        favorite,
        filterable,
      } = this.resource

      if (this.resource.type === CustomFieldTypes.List && this.resourceId) {
        this.resource.values = this.resource.values?.filter(item => this.oldValues.includes(item)).length === 0
          ? this.resource.newValues
          : this.resource.values?.filter(item => this.oldValues.includes(item))
        this.resource.removeValues = this.oldValues?.filter(item => !this.resource.values.includes(item))
      }

      if (Array.isArray(this.resource.values)) this.resource.values.sort()
      this.resource.minCharacterCount = minCharacterCount === '' ? null : minCharacterCount
      this.resource.maxCharacterCount = maxCharacterCount === '' ? null : maxCharacterCount
      this.resource.minValue = minValue === '' ? null : minValue
      this.resource.maxValue = maxValue === '' ? null : maxValue
      this.resource.favorite = favorite === null ? false : favorite
      this.resource.filterable = filterable === null ? false : filterable
      if (this.isTypeDateTime || this.isTypeDate) {
        this.resource.readOnly = !this.resource.dateEditable
      }
      if (this.isTypeQuery) this.resource.filterable = false
      const request = this.resourceKey
        ? advancedFieldMgmtService.update(params, this.resource)
        : advancedFieldMgmtService.store(params, this.resource)

      const { error } = await this.$async(request)

      if (error) {
        this.errors = this.$loadErrors(error)
      } else {
        this.$emit('saved')
        this.close()

        if (this.resourceId) {
          return
        }

        setTimeout(
          () => this.showWarning(
            "Once custom field is in use, you won't be able to edit or delete it",
          ),
          500,
        )
      }

      this.saving = false
    },

    setForcedValues() {
      if (!!this.resourceId && this.isTypeQuery) {
        this.resource.required = false
      }
    },

    async validate() {
      this.errors = await this.yupValidate(
        advancedFieldMgmtSchema({ $t: value => this.$t(value) }),
        this.resource,
      )

      if (
        this.isTypeListLike
        && this.$lodash.get(this.resource, 'values', []).length === 0
      ) {
        const index = this.errors.length === 0 ? 0 : this.errors.length - 1
        this.$set(this.errors, index, {
          field: 'values',
          defaultMessage: this.$t('Required'),
        })
      }
    },
  },
}
</script>
